import { setupEmbeddedApp } from '@uc-tm/modal-loader';
import {
  EmbeddedApp,
  Modal,
  ModalConfig,
  createAddToastAction,
  createAnalyticsAction,
  createAuthAction,
  createAutoScrollAction,
  createChangeContentVisibilityAction,
  createErrorAction,
  createFullWindowAction,
  createGetResourceAction,
  createHistoryPushAction,
  createHistoryReplaceAction,
  createNavigateAction,
  createOpenTrowserAction,
  isInIframe,
} from '@uc/compass-app-bridge';
import type {
  ContentVisibilityVariant,
  ToastOptions,
  TrowserVariant,
} from '@uc/compass-app-bridge/dist/actions';
import { Deal } from '@uc/thrift2npme/dist/deals/deals_service';
import type { ProcessedListing } from '@uc/thrift2npme/dist/listing_translation/processed_listing';
import { SuggestionCategory } from '@uc/thrift2npme/dist/search_suggest/search_suggest_model_v2';
import debounce from 'debounce-promise';
import { isEqual } from 'lodash';
import throttle from 'lodash/throttle';
import {
  autorun,
  computed,
  makeObservable,
  observable,
  runInAction,
} from 'mobx';
import type { State as RouteState } from 'router5';
import Analytics from 'src/analytics';
import { DEBOUNCE_INTERVAL, SYNC_DMS_DATA_TIMEOUT } from 'src/constants';
import { setupFullstory } from 'src/fullstory';
import logger from 'src/logger';
import { updateRoleTitle } from 'src/models/transactions/roles';
import {
  disableAnchorClick,
  disableComboAnchorClick,
} from 'src/utils/disable-anchor-click';
import { getQueryParam } from 'src/utils/urls';
import type { AppStore } from './app-store';

export type EmbeddedPlatform = 'unknown' | 'compass';
export type EmbeddedFeature =
  | 'documents'
  | 'signatures'
  | 'offers'
  | 'offers-sellside'
  | 'templates-and-clauses';
const BT_GLIDE_SERVICE_IDS = {
  documents: 'bt-glide-documents',
  signatures: 'bt-glide-signatures',
  offers: 'bt-glide-offers',
  'offers-sellside': 'bt-glide-offers-sellside',
  'templates-and-clauses': 'glide-templates-and-clauses',
};
export type EmbeddedPosition = {
  top: number;
  left: number;
  viewportHeight: number;
  viewportWidth: number;
  height: number;
  width: number;
};
type OmnisearchListingsEnabled = {
  enabled: boolean;
};
export type OmnisearchListingSuggestion = {
  id: string;
  info: {
    listingStatus: number;
  };
  redirectUrl: string;
  source: number;
  subText: string;
  text: string;
  ucGeoId: string;
};
type OmnisearchListingSuggestions = {
  status: number;
  suggestions: {
    categories: {
      name: number;
      label: string;
      items: OmnisearchListingSuggestion[];
    }[];
  };
};
type DmsTransactionData = {
  isSyncingOfferInfoLocked: boolean;
  isSyncingListingInfoLocked: boolean;
};

type DmsOfferData = {
  // unset is used to track if we got a response from the bridge. It'll default to true until then
  unset?: boolean;
  isDmsOfferComplete?: boolean;
  isDmsOfferSubmitted?: boolean;
  isDmsListingComplete?: boolean;
  isDmsListingSubmitted?: boolean;
  doesDmsListingExist?: boolean;
  doesDmsOfferExist?: boolean;
};

type OmnisearchListingDetailRes = {
  status: number;
  listing: ProcessedListing;
};

export type OptyFeatures = {
  showSigningCertificate?: boolean;
  isCrossTeamSharingEnabled?: boolean;
  isBtSimplificationPhase1Enabled?: boolean;
  isDocumentsAgentsEnabled?: boolean;
  isTMDocsUploadRestrictionsEnabled?: boolean;
  tmProductsDocsUseProxyDocumentAndResize?: boolean;
  tmProductsDocsUseProxyDownload?: boolean;
  tmProductsDocsUseProxyDownloadV2?: boolean;
  tmProductsDocsUseProxyUpload?: boolean;
  tmProductsDocsUseProxyThumbnail?: boolean;
  tmProductsDocsUseProxyZip?: boolean;
  tmProductsDisplayAssociationTerms?: boolean;
};

const COMPASS_COMMON_ROUTES = [
  /^flow\.page.*$/,
  /^account\.integrations.*$/,
  /^transactions\.transaction\.activity.*$/,
  /^transactions\.transaction\.documents\.document.*$/,
];

type InterceptContext = {
  features: EmbeddedFeature[];
  getUrl: (toState: RouteState) => string;
};

const ROUTE_INTERCEPTORS: Record<string, InterceptContext> = {
  'transactions.transaction.properties.property': {
    features: ['offers'],
    getUrl: ({ params: { propertyId, tab } }) =>
      `/offer/${propertyId}/${tab ? `?tab=${tab}` : ''}`,
  },
  'transactions.transaction.offers.offer': {
    features: ['offers', 'offers-sellside'],
    getUrl: ({ params: { propertyId, offerId } }) =>
      `/offer/offers/${offerId}/prepare/?propertyId=${propertyId}`,
  },
  'transactions.transaction.documents': {
    features: ['documents'],
    getUrl: ({ params: { folderId } }) =>
      `/files/documents/${folderId ? `?folderId=${folderId}` : ''}`,
  },
  /** Prefixed with feature name, see `navigateToFlow()` */
  'signatures.flow.page': {
    features: [],
    getUrl: ({ params: { flowId } }) =>
      `/files/signature-requests/flow/${flowId}`,
  },
  'transactions.transaction.signature-requests.detail': {
    features: [],
    getUrl: ({ params: { signatureRequestId } }) =>
      `/files/signature-requests/${signatureRequestId}`,
  },
};

type InterceptorResponse = {
  newState: RouteState;
  preventNavigation: boolean;
};

type GetRootRouteFn = (embeddedParams: any) => RouteState | undefined;

function getTxnRootRouteGetter(rootRouteName: string): GetRootRouteFn {
  return (embeddedParams: any) =>
    embeddedParams.transactionId
      ? {
          name: rootRouteName,
          params: {
            transactionId: embeddedParams.transactionId,
          },
          path: '',
        }
      : undefined;
}

const EMBEDDED_ROUTING: {
  [key in EmbeddedPlatform]?: {
    allowed: Partial<Record<EmbeddedFeature, RegExp[]>>;
    getRootRoute: Partial<Record<EmbeddedFeature, GetRootRouteFn>>;
    interceptor?: (
      embeddedApp: EmbeddedAppStore,
      feature: EmbeddedFeature,
      toState: RouteState
    ) => InterceptorResponse;
  };
} = {
  compass: {
    allowed: {
      documents: [
        ...COMPASS_COMMON_ROUTES,
        /^clauses.*$/,
        /^transactionTemplates.*$/,
        /^transactions\.transaction\.documents.*$/,
        /^transactions\.transaction\.items\.item.*$/,
        /^transactions\.transaction\.disclosurePackage.*$/,
      ],
      signatures: [
        ...COMPASS_COMMON_ROUTES,
        /^transactions\.transaction\.signature-requests.*$/,
      ],
      offers: [
        ...COMPASS_COMMON_ROUTES,
        /^transactions\.transaction\.properties.*$/,
        /^transactions\.transaction\.offers.*$/,
        /^transactions\.transaction\.signature-requests.*$/,
        /^transactionTemplates.*$/,
        /^clauses.*$/,
      ],
      'offers-sellside': [
        ...COMPASS_COMMON_ROUTES,
        /^transactions\.transaction\.offers.*$/,
        /^transactions\.transaction\.signature-requests.*$/,
        /^transactionTemplates.*$/,
        /^clauses.*$/,
      ],
      'templates-and-clauses': [/.*/],
    },
    getRootRoute: {
      documents: getTxnRootRouteGetter('transactions.transaction.documents'),
      signatures: getTxnRootRouteGetter(
        'transactions.transaction.signature-requests'
      ),
      offers: getTxnRootRouteGetter('transactions.transaction.properties'),
      'offers-sellside': getTxnRootRouteGetter(
        'transactions.transaction.offers'
      ),
      'templates-and-clauses': () => ({
        name: 'templates',
        params: {},
        path: '',
      }),
    },
    interceptor: (embeddedApp, feature, toState) => {
      // TODO TJ-38101 when removing this flag, incorporate this route interceptor into the main definition for ROUTE_INTERCEPTORS.
      if (embeddedApp.parent.features.isBtSimplificationPhase1Enabled) {
        ROUTE_INTERCEPTORS['transactions.transaction.properties.property'] = {
          ...ROUTE_INTERCEPTORS['transactions.transaction.properties.property'],
          getUrl: ({ params: { propertyId, tab } }) =>
            `/offer-management/${propertyId}/${tab ? `?tab=${tab}` : ''}`,
        };
        ROUTE_INTERCEPTORS['transactions.transaction.offers.offer'] = {
          ...ROUTE_INTERCEPTORS['transactions.transaction.offers.offer'],
          getUrl: ({ params: { propertyId, offerId } }) =>
            `/offer-management/offers/${offerId}/prepare/?propertyId=${propertyId}`,
        };
      }

      for (const route in ROUTE_INTERCEPTORS) {
        if (
          toState.name === route &&
          !ROUTE_INTERCEPTORS[route].features.includes(feature)
        ) {
          embeddedApp.navigateToUrl(
            ROUTE_INTERCEPTORS[route].getUrl(toState),
            'parent',
            true
          );
          return {
            newState: toState,
            preventNavigation: true,
          };
        }
      }

      return {
        newState: toState,
        preventNavigation: false,
      };
    },
  },
};

const A_WEEK_IN_MILLISECOND = 7 * 24 * 3600 * 1000;

export default class EmbeddedAppStore {
  // window.isInIFrame: to overwrite the check if we are in an iframe or not. Needed for Cypress E2E tests (they run inside an iframe).
  @observable public isEmbedded: boolean =
    window.isInIFrame !== undefined ? window.isInIFrame : isInIframe();
  @observable public embeddedPlatform: EmbeddedPlatform | null = null;
  @observable public embeddedFeature: EmbeddedFeature | null = null;
  private embeddedParams: any = {};
  @observable public isFullWindow = false;
  @observable public isPresentationMode = false;
  @observable public lockFullScreenKey = '';
  @observable public embeddedPosition: EmbeddedPosition = {
    top: 0,
    left: 0,
    viewportHeight: 0,
    viewportWidth: 0,
    height: 0,
    width: 0,
  };
  @observable public dmsTransactionData: DmsTransactionData | null = null;
  @observable public dmsOfferData: DmsOfferData | null = { unset: true };
  @observable
  public dealData: Deal | null = null;
  @observable public optyFeatures: OptyFeatures = {};
  private compassToastVariant: Array<string> = [
    'info',
    'success',
    'highlight',
    'error',
  ];
  private hasFiredContentReady = false;
  private embeddedApp: EmbeddedApp | null = null;
  private autoResizeEnabled = true;
  parent: AppStore;

  public constructor(parent: AppStore) {
    makeObservable(this);
    this.parent = parent;
    this.setEmbeddedPlatformFeature();
  }

  private setEmbeddedPlatformFeature = () => {
    if (this.isEmbedded) {
      const embeddedFeature = getQueryParam(
        'embeddedFeature',
        true
      ) as EmbeddedFeature;
      if (embeddedFeature) {
        const embeddedPlatform = BT_GLIDE_SERVICE_IDS[embeddedFeature]
          ? 'compass'
          : 'unknown';
        const embeddedParams = JSON.parse(
          getQueryParam('embeddedParams', true) || '{}'
        );
        runInAction(() => {
          this.embeddedPlatform = embeddedPlatform;
          this.embeddedFeature = embeddedFeature;
          this.embeddedParams = embeddedParams;
        });
      }
    }
  };

  @computed
  private get height(): '100vh' | 'auto' {
    return this.isFullWindow ? '100vh' : 'auto';
  }

  getRootElementHeight = () => window.document.documentElement.style.height;

  private resizeHeight() {
    // set height will trigger resizeObserver defined in EmbeddedApp (https://github.com/UrbanCompass/uc-frontend/blob/a329b35f6ba11e63913ff96adfc7eddcdef436d6/packages/compass-app-bridge/src/EmbeddedApp/EmbeddedApp.ts#L64)
    // It also sets the iframe element's height in Compass(https://github.com/UrbanCompass/uc-frontend/blob/a329b35f6ba11e63913ff96adfc7eddcdef436d6/packages/compass-app-bridge/src/ParentApp/ParentApp.ts#L78) when it detects a height change, so we need to keep using Math.ceil() to ensure that the results of both operations are consistent to avoid dead loops
    const height =
      this.height === 'auto'
        ? `${Math.ceil(parseFloat(this.getRootElementHeight()))}px`
        : this.height;
    if (window.document.documentElement.style.height !== height) {
      window.document.documentElement.style.height = height;
    }
    if (window.document.body.style.height !== height) {
      window.document.body.style.height = height;
    }
  }

  private autoScrollListener = async (event: MouseEvent) => {
    await this.embeddedApp!.dispatch(createAutoScrollAction(event.clientY), {
      oneway: true,
    });
  };

  private throttleAutoScrollListener = throttle(this.autoScrollListener, 20);

  public async initialize() {
    if (this.isEmbedded && this.embeddedFeature) {
      window.Glide.isEmbedded = true;
      // Hack: use resizeHeight to have CAB's rezising work accurately
      autorun(() => {
        this.resizeHeight();
      });
      await this.initializeEmbeddedApp();
    }
  }

  // Usually, the embedded app initiates routing changes and sends history over the app bridge so that the parent app can stay in sync.
  // But there is at least one case where the parent initiates a routing change that the embedded app needs to react to.
  // (Namely, when the user clicks the Offers tab in BT to go back to the landing view.) In this case,
  // we need to make sure that the embedded app does not send a history event back over the app bridge.
  private navigateNotSendToBridge = (
    payload: any,
    actionType: 'HISTORY_PUSH' | 'HISTORY_REPLACE'
  ) => {
    const { pathname } = payload;
    // use router5's `navigate` in this
    const { matchPath, navigate } = this.parent.router!.router!;
    if (matchPath && navigate) {
      const matchRoute = pathname
        ? matchPath(pathname)
        : this.getFeatureRootRoute();
      if (matchRoute) {
        const { name, params } = matchRoute;
        const replace = actionType === 'HISTORY_REPLACE';
        navigate(name, params, { replace });
      } else {
        logger.error(`parent send pathname not match any route: ${pathname}`);
      }
    }
  };

  private initializeEmbeddedApp = async () => {
    this.embeddedApp = EmbeddedApp.create({
      serviceId: BT_GLIDE_SERVICE_IDS[this.embeddedFeature!],
      debug: window.Glide.env === 'local',
      originValidation: this.parent.features.restrictCabEventOrigin,
    });
    autorun(() => {
      // hack to access private config, flags are not ready on startup, so dynamically set after real flags are loaded
      (this.embeddedApp as any).transport.config.originValidation =
        this.parent.features.restrictCabEventOrigin;
    });
    await this.embeddedApp.isReady();
    const resp = await this.embeddedApp.dispatch(createAuthAction());
    if (resp.token) {
      window.setAuthBearer?.(resp.token);
    }

    this.embeddedApp.subscribe('AUTHENTICATE', (payload: any) => {
      if (payload !== undefined && payload.token) {
        window.setAuthBearer?.(payload.token);
      }
    });
    window.document.onclick = disableComboAnchorClick;
    window.document.oncontextmenu = disableAnchorClick;
    window.document.onauxclick = disableAnchorClick;

    this.embeddedApp.subscribe('HISTORY_PUSH', (payload: any) =>
      this.navigateNotSendToBridge(payload, 'HISTORY_PUSH')
    );
    this.embeddedApp.subscribe('HISTORY_REPLACE', (payload: any) =>
      this.navigateNotSendToBridge(payload, 'HISTORY_REPLACE')
    );

    this.embeddedApp.subscribe('POSITION_CHANGE', (embeddedPosition: any) => {
      runInAction(() => {
        this.embeddedPosition = embeddedPosition;
      });
    });

    this.embeddedApp.subscribe(
      'GET_RESOURCE',
      async (payload: { resourceType: string; query?: string }) => {
        switch (payload.resourceType) {
          case 'refreshTransactionData':
            if (payload.query) {
              const transactionId = payload.query;
              this.parent.transactions.getOrFetchTransaction(transactionId, {
                force: true,
              });
              return true;
            }
            return false;
          default:
            return false;
        }
      }
    );

    setupEmbeddedApp(this.embeddedApp);
    setupFullstory();

    // Update dmsTransactionData @observable value every 2s after initializing
    // embeddedApp. This can be done with little performance hit because the parent
    // responds directly from data it has in memory, which is never going to trigger
    // an api call, nor other expensive i/o
    this.updateDmsTransactionData();
    this.updateDmsOfferData();
    setInterval(() => {
      this.updateDmsTransactionData();
      this.updateDmsOfferData();
    }, SYNC_DMS_DATA_TIMEOUT);
    this.updateOptyFeatures();
    this.updateDealData();

    // Configures window.analytics to intercept the analytics events from @uc/analytics-definitions
    // The analytics events are sent through the app-bridge and sent to segment by the parent app.
    window.analytics = {
      track: (name, props, options = {}, callback) => {
        if (!this.embeddedApp) {
          Analytics().track(name, props);
          return;
        }
        this.sendAnalytics(name, props, callback);
      },
    };
  };

  public getFeatureRootRoute(): RouteState | undefined {
    const getRootRoute =
      EMBEDDED_ROUTING[this.embeddedPlatform!]?.getRootRoute[
        this.embeddedFeature!
      ] || (() => undefined);
    return getRootRoute(this.embeddedParams);
  }

  public async toggleFullWindow(isFullWindow: boolean, lock?: string) {
    if (!this.embeddedApp) {
      return;
    }

    const getLockValue = (): string => {
      if (isFullWindow && lock) {
        return lock;
      }
      if (isFullWindow && !lock) {
        return this.lockFullScreenKey;
      }
      return '';
    };

    if (!isFullWindow && (lock ?? '') !== this.lockFullScreenKey) {
      return;
    }

    // Currently assuming the full window requests will always be granted by parent
    await this.embeddedApp.dispatch(createFullWindowAction(isFullWindow), {
      oneway: true,
    });

    runInAction(() => {
      this.isFullWindow = isFullWindow;
      if (isFullWindow) {
        // No need to comunicate resizes to parent while on full window mode
        this.embeddedApp!.toggleAutoResize(false);
      } else if (this.autoResizeEnabled) {
        // If autoResize was enabled prior to entering full window, then re-enable it
        this.embeddedApp!.toggleAutoResize(true);
      }
      this.lockFullScreenKey = getLockValue();
    });
  }

  public async togglePresentationMode(isPresentationMode: boolean) {
    if (!this.embeddedApp) {
      return;
    }

    this.isPresentationMode = isPresentationMode;
  }

  public fireContentReady() {
    if (!this.embeddedApp || this.hasFiredContentReady) {
      return;
    }
    this.embeddedApp.dispatch({ type: 'CONTENT_READY' }, { oneway: true });
    this.hasFiredContentReady = true;
  }

  public async getParentUrl() {
    if (!this.embeddedApp) {
      return null;
    }
    const res = (await this.embeddedApp.dispatch(
      createGetResourceAction({ resourceType: 'url' }),
      { timeoutDuration: 10000 }
    )) as { url: string };
    return res.url;
  }

  public async sendError(message: string, context?: Record<string, unknown>) {
    if (!this.embeddedApp) {
      return;
    }

    // Currently assuming the full window requests will always be granted by parent
    await this.embeddedApp.dispatch(
      createErrorAction(message, {
        glideTxnId: this.parent.router?.route?.params?.transactionId,
        glideUserId: this.parent.account?.user?.id,
        userEmail: this.parent.account?.user?.contact?.email,
        route: this.parent.router?.route,
        fromRoute: this.parent.router?.fromRoute,
        ...context,
      }),
      {
        oneway: true,
      }
    );
  }

  public startFullWindow(lock?: string) {
    return this.toggleFullWindow(true, lock);
  }

  public finishFullWindow(lock?: string) {
    return this.toggleFullWindow(false, lock);
  }

  public toggleAutoResize(autoResizeEnabled: boolean) {
    if (!this.embeddedApp) {
      return;
    }

    // Do not acutally re-enable auto-resize while on full window mode. Do
    // update this.autoResizeEnabled to true so it does get re-enabled after
    // exiting full window mode.
    if (!this.isFullWindow) {
      this.embeddedApp.toggleAutoResize(autoResizeEnabled);
    }
    runInAction(() => {
      this.autoResizeEnabled = autoResizeEnabled;
    });
  }

  public enableAutoResize() {
    return this.toggleAutoResize(true);
  }

  public disableAutoResize() {
    return this.toggleAutoResize(false);
  }

  public toggleAutoScroll(autoScrollEnabled: boolean) {
    if (!this.embeddedApp) {
      return;
    }

    if (autoScrollEnabled) {
      document.addEventListener('mousemove', this.throttleAutoScrollListener);
    } else {
      document.removeEventListener(
        'mousemove',
        this.throttleAutoScrollListener
      );
    }
  }

  public enableAutoScroll() {
    return this.toggleAutoScroll(true);
  }

  public disableAutoScroll() {
    return this.toggleAutoScroll(false);
  }

  public interceptFeatureNavigation(route: RouteState): InterceptorResponse {
    // Check if the route is navigating to a different feature and intercept the navigation if needed
    const embeddedRouting =
      EMBEDDED_ROUTING[this.embeddedPlatform ?? 'unknown'];
    if (!embeddedRouting?.interceptor || !this.embeddedFeature) {
      return {
        newState: route,
        preventNavigation: false,
      };
    }
    const interceptorRes = embeddedRouting.interceptor(
      this,
      this.embeddedFeature,
      route
    );
    return interceptorRes;
  }

  public navigateToUrl = async (
    url: string,
    target: 'parent' | 'blank',
    relativeToFolder?: boolean
  ) => {
    if (!this.embeddedApp) {
      return;
    }

    const navigateAction = createNavigateAction({
      url,
      target,
    });
    if (relativeToFolder && navigateAction.payload) {
      (navigateAction.payload! as any).relativeToFolder = true;
    }

    await this.embeddedApp.dispatch(navigateAction, { oneway: true });
  };

  public embeddedFeatureRoute(toState: RouteState): {
    newState: RouteState;
    preventNavigation?: boolean;
    errorMsg?: string;
  } {
    if (
      !this.embeddedFeature ||
      !this.embeddedPlatform ||
      !EMBEDDED_ROUTING[this.embeddedPlatform]
    ) {
      return {
        newState: toState,
      };
    }

    const interceptorRes = this.interceptFeatureNavigation(toState);
    if (interceptorRes.preventNavigation) {
      return {
        newState: toState,
        preventNavigation: true,
      };
    }
    const newState = interceptorRes ? interceptorRes.newState : toState;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { allowed: allowedRoutes } = EMBEDDED_ROUTING[this.embeddedPlatform]!;

    const allowedFeatureRoutes = allowedRoutes[this.embeddedFeature] ?? [];
    if (allowedFeatureRoutes.some((pattern) => pattern.test(toState.name))) {
      return {
        newState,
      };
    }

    return {
      newState,
      errorMsg: `Route "${toState.name}" is not supported on ${this.embeddedPlatform}:${this.embeddedFeature} embedded UX`,
    };
  }

  public getContacts(query: string) {
    if (!this.embeddedApp) {
      return [];
    }

    return this.embeddedApp.dispatch(
      createGetResourceAction({
        resourceType: 'contacts',
        query,
      })
    );
  }

  public async getDealUniqueEmail() {
    if (!this.embeddedApp) {
      return null;
    }

    const { dealUniqueEmail } = await this.embeddedApp.dispatch(
      createGetResourceAction({
        resourceType: 'dealUniqueEmail',
      })
    );
    return dealUniqueEmail;
  }

  private async updateDmsTransactionData(): Promise<undefined> {
    if (!this.embeddedApp) {
      return;
    }

    const dmsTransactionData = (await this.embeddedApp.dispatch(
      createGetResourceAction({
        resourceType: 'dmsTransactionData',
      })
    )) as DmsTransactionData;

    runInAction(() => {
      // Only update `this.dmsTransactionData` if some of its underlying values actually
      // changed (to prevent unwanted re-renders in observing components if there are no
      // real changes)
      if (
        dmsTransactionData?.isSyncingOfferInfoLocked !==
          this.dmsTransactionData?.isSyncingOfferInfoLocked ||
        dmsTransactionData?.isSyncingListingInfoLocked !==
          this.dmsTransactionData?.isSyncingListingInfoLocked
      ) {
        this.dmsTransactionData = dmsTransactionData;
      }
    });
  }

  private async updateDmsOfferData(): Promise<undefined> {
    if (!this.embeddedApp) {
      return;
    }

    const dmsOfferData = (await this.embeddedApp.dispatch(
      createGetResourceAction({ resourceType: 'dmsOfferData' })
    )) as DmsOfferData;

    runInAction(() => {
      if (!isEqual(dmsOfferData, this.dmsOfferData)) {
        this.dmsOfferData = dmsOfferData;
      }
    });
  }

  @computed
  public get canAddAcceptedOffer(): boolean {
    return !this.dmsOfferData?.unset && !this.dmsOfferData?.doesDmsOfferExist;
  }

  public async updateOptyFeatures() {
    if (!this.embeddedApp) {
      return;
    }
    const optyFeatures = await this.embeddedApp.dispatch(
      createGetResourceAction({ resourceType: 'optyFeatures' })
    );

    runInAction(() => {
      if (!isEqual(optyFeatures, this.optyFeatures)) {
        this.optyFeatures = optyFeatures ?? {};
      }
    });
    updateRoleTitle(this.optyFeatures);
  }

  public async updateDealData() {
    if (!this.embeddedApp) {
      return;
    }
    const dealData = await this.embeddedApp.dispatch(
      createGetResourceAction({ resourceType: 'dealData' })
    );

    runInAction(() => {
      if (!isEqual(dealData, this.dealData)) {
        this.dealData = dealData ?? {};
      }
    });
  }

  public async getDmsTransactionData(): Promise<DmsTransactionData | null> {
    await this.updateDmsTransactionData();
    return this.dmsTransactionData;
  }

  public async getOmnisearchListingsEnabled(): Promise<boolean> {
    if (!this.embeddedApp) {
      return false;
    }

    const res = (await this.embeddedApp!.dispatch(
      createGetResourceAction({
        resourceType: 'omnisearchListings.enabled',
      })
    )) as OmnisearchListingsEnabled;
    return res.enabled;
  }

  public async getOmnisearchListingSuggestions(
    query: string
  ): Promise<OmnisearchListingSuggestion[]> {
    if (!this.embeddedApp || !query) {
      return [];
    }

    const res = (await this.embeddedApp!.dispatch(
      createGetResourceAction({
        resourceType: 'omnisearchListings.suggestions',
        query,
      }),
      { timeoutDuration: 10000 }
    )) as OmnisearchListingSuggestions;

    if (res.status !== 200) {
      throw new Error(`Error fetching listings suggestions`);
    }

    const items: OmnisearchListingSuggestion[] = [];
    (res.suggestions.categories ?? [])
      .filter((c) =>
        [SuggestionCategory.ADDRESS, SuggestionCategory.MRSID].includes(c.name)
      )
      .sort((c1, c2) => c2.name - c1.name)
      .forEach((v) => {
        items.push(...v.items);
      });
    return items;
  }

  public async getOmnisearchListingDetails(
    listingId: string
  ): Promise<ProcessedListing | null> {
    if (!this.embeddedApp || !listingId) {
      return null;
    }

    const res = (await this.embeddedApp!.dispatch(
      createGetResourceAction({
        resourceType: 'omnisearchListings.details',
        query: listingId,
      }),
      { timeoutDuration: 10000 }
    )) as OmnisearchListingDetailRes;

    if (res.status !== 200) {
      throw new Error(`Error fetching listing details ${res}`);
    }

    return res.listing;
  }

  public getContactsDebounce = debounce(this.getContacts, DEBOUNCE_INTERVAL);

  public async addToast(
    options: ToastOptions
  ): Promise<{ actionClicked?: true; dismissed?: true }> {
    if (!this.embeddedApp) {
      return {};
    }

    const { variant, duration } = options;

    // if toast varient isn't one that is supported, it will default to 'info'
    const _variant = this.compassToastVariant.includes(variant)
      ? variant
      : 'highlight';

    // Compass toasts defaults to indefinite when no duration is set, so setting
    // default to 4500, indefinite can be set with '0', which is antd's convention
    const _duration = duration === undefined ? 4500 : duration;

    /**
     * The toasts will be shown one by one, although they may occur simultaneously.
     *
     * To receive the result of the dispatch, `oneway` is set to false.
     * To avoid timeout, `timeoutDuration` is set to one week, regardless of whether toast is always on display or not.
     */

    return this.embeddedApp.dispatch(
      createAddToastAction({
        ...options,
        variant: _variant,
        duration: _duration,
      }),
      { oneway: false, timeoutDuration: A_WEEK_IN_MILLISECOND }
    );
  }

  public async openTrowserAction(
    variant: TrowserVariant
  ): Promise<{ actionClicked?: true; dismissed?: true }> {
    return this.embeddedApp
      ? this.embeddedApp.dispatch(createOpenTrowserAction(variant), {
          oneway: false,
          timeoutDuration: A_WEEK_IN_MILLISECOND,
        })
      : {};
  }

  public async toggleContentAction(
    component: ContentVisibilityVariant,
    visible: boolean
  ): Promise<{ actionClicked?: true; dismissed?: true }> {
    return this.embeddedApp
      ? this.embeddedApp.dispatch(
          createChangeContentVisibilityAction({ component, visible }),
          { oneway: false, timeoutDuration: A_WEEK_IN_MILLISECOND }
        )
      : {};
  }

  public async setHeight(height: number, initiator?: string) {
    return this.embeddedApp
      ? this.embeddedApp.dispatch(
          {
            type: 'RESIZE_FRAME',
            payload: { height, initiator },
          },
          { oneway: true }
        )
      : {};
  }

  public sendHistory(
    pathname: string,
    search?: string,
    hash?: string,
    replace?: boolean
  ) {
    if (!this.embeddedApp) {
      return [];
    }

    const historyAction = replace
      ? createHistoryReplaceAction
      : createHistoryPushAction;

    return this.embeddedApp.dispatch(
      historyAction({
        pathname,
        search,
        hash,
      }),
      { oneway: true }
    );
  }

  public triggerModal(modalConfig: ModalConfig) {
    if (!this.embeddedApp) {
      return;
    }

    Modal.create(this.embeddedApp, modalConfig);
  }

  public async sendAnalytics(
    name: string,
    props: Record<string, unknown>,
    resolveCallback?: () => void
  ) {
    // When sending events using the @uc/analytics-definitions package,
    // the resolveCallback will be the resolve function that resolve the function Promise
    // It should always resolve, regardless of the analytics outcome, not to block the UI
    if (!this.embeddedApp || !this.parent.features.enableAppBridgeAnalytics) {
      resolveCallback?.();
      return;
    }

    let analyticsBridgeWaitTimeout: number | undefined;
    try {
      // If the bridge takes too long to respond, just continue
      analyticsBridgeWaitTimeout = window.setTimeout(() => {
        resolveCallback?.();
      }, 200);
      // Not using oneway because we sometimes need to wait for the analytics to be
      // read by the parent app. Otherwise, we might loose datapoints during redirects.
      await this.embeddedApp.dispatch(
        createAnalyticsAction({
          name,
          props,
          enrichWithDealData: true,
        })
      );
    } catch (err) {
      logger.error(err);
    } finally {
      resolveCallback?.();
      clearTimeout(analyticsBridgeWaitTimeout);
    }
  }

  public isCurrentTransaction(transactionId: string) {
    return (
      !this.embeddedParams.transactionId ||
      this.embeddedParams.transactionId === transactionId
    );
  }

  public trackUserAcceptTerms(providerId: string) {
    if (this.embeddedApp && this.parent.account?.user?.compassPersonId) {
      this.embeddedApp.dispatch(
        {
          type: 'SEND_REUQEST',
          payload: {
            type: 'trackUserAcceptTerms',
            data: {
              personId: this.parent.account.user.compassPersonId,
              providerId,
            },
          },
        },
        { oneway: true }
      );
    }
  }
}
